@import 'styles/mixins';

.button_container {
    position: absolute;
    right: 0.85rem;
    top: 50%;
    width: 1.75rem;
    height: 1.75rem;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: rgba(244, 244, 244, 0.2);
    transition: all 150ms ease;

    &:hover {
        cursor: pointer;
        .cross {
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }

    &:active {
        background-color: rgba(244, 244, 244, 0.325);
    }
}

.progress_bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
}

.cross {
    width: 0.875rem;
    height: 0.875rem;
    transition: all 150ms ease;
    @include centerAbsolutely();

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 0.13125rem;
        height: 100%;
        transform-origin: center center;
        background-color: #f4f4f4;
        border-radius: 0.45rem;
    }

    &::before {
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
    }

    &::after {
        right: 50%;
        transform: rotate(-45deg) translateX(50%);
    }
}
