@import 'styles/variables';
@import 'styles/mixins';

$decoration-color: var(--color, #{$light});
$bulletSize: 0.65em;

.wrapper {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    padding-right: $bulletSize;
}

.bullet {
    width: $bulletSize;
    height: $bulletSize;
    margin-top: 1em - $bulletSize;
    justify-content: center;
    border-radius: 50%;
    background-color: $decoration-color;
    margin-bottom: auto;
}

.line {
    flex: 1;
    width: 2px;
    position: relative;
    align-self: center;
    transform-origin: top;
    transform: scaleY(0);
    transition: transform 1250ms ease-out;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: calc(100% - 12px);
        bottom: 0;
        opacity: 0.5;
        background-color: $decoration-color;
        border-radius: 1em;
    }

    &Visible {
        transform: scaleY(1);
    }
}

.hiddenOnMobile {
    @media screen and (max-width: 476px) {
        display: none;
    }
}
