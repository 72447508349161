// TODO: Vorbert -> dorobić komponent z flexem, który by ogrywał takie przypadki
.buttonsContainer {
    margin-top: 0.75rem;
    display: flex;
    justify-content: flex-end;
}

.formSectionWrapper {
    padding-top: 0.25rem;
}
