@import './variables.scss';

// Stylowanie dla edytora HTML w kodowym trybie
.ql-html {
    &-popupContainer {
        color: $dark;
    }

    &-popupTitle {
        margin-bottom: 0.5rem !important;
    }

    &-textContainer {
        height: calc(100% - 5px) !important;
    }

    &-buttonGroup {
        left: unset !important;
        right: 42px;

        button {
            padding: 0.35em 0.5em;
            border: none;
            background-color: $grey;
            transition: background 150ms ease;
            outline: none;

            &:hover {
                cursor: pointer;
                background-color: darken($grey, 7);
            }
        }
    }
}

// Stylowanie wnętrza komponentu z tabami, który posiada animacje przejścia pomiędzy tabami.
.k-content {
    .k-animation-container {
        width: 100%;
    }
}
