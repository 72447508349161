@import 'styles/variables';
@import 'styles/mixins';

.list {
    margin-top: 0.5em;
    text-align: left;
}

.withOverflow {
    max-height: pxToRem(230);
    overflow: auto;
}
