@import './breakpoints.scss';

$accent: hsl(43, 99%, 53%);
$saturatedAccent: hsl(50, 100%, 55%);
$disabledAccent: #b79c57;

$info: hsl(216, 90%, 53%);
$saturatedInfo: hsl(215, 100%, 45%);
$disabledInfo: #5c87c0;

$error: hsl(1, 77%, 55%);
$saturatedError: hsl(0, 90%, 55%);
$disabledError: #c67c7c;

$success: hsl(145, 63%, 42%);
$saturatedSuccess: hsl(150, 80%, 34%);
$disabledSuccess: #528367;

$dark: hsl(207, 12%, 15%);
$lightenDark: hsl(220, 12%, 35%);
$opaqueDark: hsla(207, 12%, 12%, 0.65);

$light: #e6e6e6;
$darkenLight: hsl(210, 3%, 53%);
$opaqueLight: hsla(0, 0%, 90%, 0.7);

$grey: hsl(0, 0%, 71%);
$saturatedGrey: hsl(0, 0%, 35%);

$backgroundColor: #2d343c;

$tableBorderColor: hsla(30, 100%, 15%, 0.2);
$tableEvenRowColor: #eee;
$tableHoverColor: darken($tableEvenRowColor, 10);

// NOTE: Ta zmienna zmienia rozmiary w CAŁEJ aplikacji
// Aktualnie Sławek dał wymóg tego żeby apka była zeskalowana w 90%, dlatego też znajduje się tutaj 0.9
$app-size-multiplier: 0.9;

$maxLayoutWidth: 1400px * $app-size-multiplier;
$mobilePageOffset: 5vw;

$defaultTransition: 350ms;
$quickTransition: 150ms;

$defaultVerticalMargin: 36px;
$defaultHorizontalMargin: 72px;

$IEVerticalOffset: 1rem;
$IEHorizontalOffset: 1rem;
