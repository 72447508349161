@import '../variables.scss';

$scrollbarBackground: $dark;
$scrollbarThumbColor: $accent;

* {
    & {
        scrollbar-width: thin;
        scrollbar-color: $scrollbarThumbColor $scrollbarBackground;
    }

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbarBackground;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbarThumbColor;
        border-radius: 6px;
        border: 3px solid $scrollbarBackground;
    }
}
