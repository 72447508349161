@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
    align-items: center;
    text-align: center;
    @include centerAbsolutely();

    @media screen and (max-width: 760px) {
        width: 100%;
    }
}

.statusCode {
    color: $accent;
    font-size: 12em;
    font-weight: 500;
    line-height: 1;
}

.description {
    &Long {
        font-size: 1.25em;
        max-width: 400px;
        color: $darkenLight;
    }

    &Short {
        font-size: 2em;
    }
}
