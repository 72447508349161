@import 'styles/variables';
@import 'styles/mixins';

// TODO Vorbert -> zmniejszyć objętość tych stylowań
.arrowSection {
    &Wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    & {
        @media screen and (min-width: 1024px) {
            flex: 1;

            &:not(:last-child) {
                margin-right: $defaultHorizontalMargin;
            }
        }

        @media screen and (max-width: 1024px) {
            & + & {
                margin-top: $defaultVerticalMargin;
            }
        }
    }
}

.confirmationText {
    margin-top: pxToRem(4);
    color: $opaqueLight;
}

.signatureWrapper {
    $signatureOffset: $defaultVerticalMargin * 2;

    color: $light;
    margin-bottom: $signatureOffset;
    margin-top: $signatureOffset !important;

    @media screen and (max-width: 760px) {
        font-size: pxToRem(24);
        text-align: center;
        margin-bottom: $defaultVerticalMargin;
        margin-top: $defaultVerticalMargin * .5 !important;
    }
}

.stepHeader {
    // TODO Vorbert -> zerknąć na "ważność" tej reguły
    & {
        color: $opaqueLight !important;

        @media screen and (max-width: 760px) {
            font-size: #{pxToRem(32)};
        }
    }

    &Row {
        margin-bottom: var(--vertical-padding);
    }
}
