@import 'styles/variables.scss';

$colors: (
    'accent': (
        'color': $accent,
        'text-color': $dark,
        'saturated-color': $saturatedAccent
    ),
    'info': (
        'color': $info,
        'text-color': $light,
        'saturated-color': $saturatedInfo
    ),
    'error': (
        'color': $error,
        'text-color': $light,
        'saturated-color': $saturatedError
    ),
    'success': (
        'color': $success,
        'text-color': $light,
        'saturated-color': $saturatedSuccess
    ),
    'dark': (
        'color': $dark,
        'text-color': $light,
        // TODO Vorbert -> do ogarnięcia
        'saturated-color': $dark
    ),
    'light': (
        'color': $light,
        'text-color': $dark,
        // TODO Vorbert -> do ogarnięcia
        'saturated-color': $light
    )
);

.colorProvider {
    @each $colorName, $colorMap in $colors {
        &--#{$colorName} {
            --color: #{map-get($map: $colorMap, $key: 'color')};
            --text-color: #{map-get($map: $colorMap, $key: 'text-color')};
            --saturated-color: #{map-get(
                    $map: $colorMap,
                    $key: 'saturated-color'
                )};
        }
    }

    &--disabled {
        --color: #{$grey};
        --text-color: #{$dark};
    }
}
