@import 'styles/variables';

.optionsWrapper {
    position: absolute;
    margin-top: 0.5em;
    top: 100%;
    right: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    border-radius: 6px;
    border: 1px solid darken($dark, 5);
    transform: translateY(1.25em);
    transition: all $defaultTransition ease;
}

.visibleWrapper {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
}
