.wrapper {
    position: relative;
    display: inline-block;
    z-index: 1;
}

.labelWrapper {
    &:hover {
        cursor: pointer;
    }
}

.icon {
    margin-left: 0.5em;
    height: 0.5em;
}
