@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
    display: flex;
    // NOTE Vorbert -> dopytać się Sławka o obsługiwane przeglądarki i zastanowić się nad poniższą linijką
    justify-content: space-between;
    align-items: center;
}

.logo {
    // TODO Vorbert -> obgadać ze Sławkiem makiety i ustalić breakpointy wraz z rozmiarami np.: loga
    width: 128px;

    @media screen and (min-width: 1024px) {
        width: 160px;
    }
}
