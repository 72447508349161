@import 'styles/variables';

.widthWrapper {
    max-width: $maxLayoutWidth;
    margin: 0 auto;
    padding: 0 var(--section-offset);

    @media screen and (max-width: 760px) {
        width: fit-content;
        align-items: flex-start;
    }
}

.companyInformation {
    background-color: $dark;
}

.accentedText {
    color: $accent;
}

.column {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 760px) {
        & + & {
            margin-top: (2rem / 3);
        }
    }
}
