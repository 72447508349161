@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import 'styles/variables';
@import 'styles/mixins';

$vertical-offset: 0.75rem;
$horizontal-offset: 3rem;
$icon-size: 2rem;
$icon-offset: 0.75rem;
$colors: (
    'success': #{$success},
    'info': #{$info},
    'warning': hsl(35, 100%, 50%),
    'error': #{$error},
    'dark': #{$dark}
);

.toast {
    --toast-color: #{map-get($map: $colors, $key: 'dark')};

    position: relative;
    padding: $vertical-offset $horizontal-offset;
    padding-left: $icon-offset;
    padding-right: 3.45rem;
    margin-left: auto;
    height: auto;
    width: fit-content;
    box-sizing: border-box;
    border-radius: 0.45rem;
    background-color: var(--toast-color);
    font-family: 'Roboto', sans-serif;
    color: $light;
    overflow: hidden;
    /* Style na potrzeby animacji */
    right: -115%;
    opacity: 0;
    transform: scale(0.7);
    transition: opacity 800ms ease-out, right 400ms ease-in-out,
        transform 500ms 200ms ease-out, height 350ms 150ms ease-in-out,
        padding 350ms 150ms ease-in-out;

    &.visible {
        right: 0;
        opacity: 1;
        transform: scale(1);
    }

    & + & {
        margin-top: $vertical-offset;
    }

    @each $colorName, $colorValue in $colors {
        &_#{$colorName} {
            --toast-color: #{$colorValue};
        }
    }
}

.icon {
    font-size: 1rem;
    color: var(--toast-color);
    @include centerAbsolutely();

    &_circle {
        position: relative;
        margin-right: $icon-offset;
        width: $icon-size;
        height: $icon-size;
        background-color: $light;
        border-radius: 50%;
    }
}

.text_container {
    padding-top: 0.35rem;
    word-break: break-word;
}

.row,
.column {
    display: flex;
    justify-content: flex-start;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}
