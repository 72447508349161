@import 'styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
}

.wrapperMaxWidth {
    max-width: $maxLayoutWidth;
}
