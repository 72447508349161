@import './variables.scss';
@import './theming.scss';
@import './utils.scss';
@import './mixins.scss';
@import './components/scrollbar.scss';

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
    // word-break: break-all;
}

html {
    font-size: 16px * $app-size-multiplier;
    line-height: 150%;

    @media screen and (min-width: $desktopWidth) {
        font-size: 18px * $app-size-multiplier;
    }
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $backgroundColor;
    color: $light;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
}

img {
    user-select: none;
    -webkit-user-drag: none;
}

li {
    list-style-type: none;
}

// TODO Vorbert -> ogarnąć ze Sławkiem, jak powinny się przedstawiac linki w aplikacji
a {
    text-decoration: none;
    color: inherit;
}

div,
p,
span {
    line-height: 1.25;
}

h2,
h4 {
    line-height: 1.3;
}

h3,
h4 {
    line-height: 1;
    margin-bottom: pxToRem(9);
}

h4 {
    font-size: 20px * $app-size-multiplier;
    font-weight: normal;

    @media screen and (min-width: $desktopWidth) {
        font-size: 24px * $app-size-multiplier;
    }
}

h3 {
    font-size: 24px * $app-size-multiplier;

    @media screen and (min-width: $desktopWidth) {
        font-size: 28px * $app-size-multiplier;
    }
}
