@import './variables.scss';

@function css-function($function, $values...) {
    @return $function + unquote('(') + $values + unquote(')');
}

@function css-min($values...) {
    @return css-function(min, $values);
}

@function css-max($values...) {
    @return css-function(max, $values);
}

@function css-clamp($values...) {
    @return css-function(clamp, $values);
}

@mixin centerAbsolutely($centerDirection: center) {
    position: absolute;

    @if $centerDirection == vertical {
        top: 50%;
        transform: translateY(-50%);
    } @else if $centerDirection == horizontal {
        left: 50%;
        transform: translateX(-50%);
    } @else {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// TODO Vorbert -> dodać do scss-owych zmiennych -> defaultDesktopFontSize
@function pxToRem($value) {
    @return #{($value / 18) + 'rem'};
}

@mixin transition($propertyName: all, $timeFunction: ease) {
    transition: $propertyName $defaultTransition $timeFunction;
}

@mixin quickTransition($propertyName: all, $timeFunction: ease) {
    transition: $propertyName $quickTransition $timeFunction;
}
