$container-offset: 0.75rem;

.toast_container {
    position: fixed;
    right: $container-offset;
    bottom: $container-offset;
    max-width: 350px;
    max-height: 100vh;
    z-index: 1000;
}
