@import 'styles/variables';

$tableBorderRadius: 8px;
$boxShadowBasicProperties: 0 1px 10px 0.5px rgba(23, 23, 23, 0.3);

.tableContainer {
    display: block;
    width: 100%;
    border-collapse: collapse;
    border-radius: $tableBorderRadius;
    color: $dark;
    font-size: 18px;
    overflow: auto;

    tbody tr {
        &:nth-of-type(even) {
            background-color: $tableEvenRowColor;
        }

        &:hover {
            background-color: $tableHoverColor;
        }
    }

    tr {
        background-color: $light;
        transition: $quickTransition;
    }

    td {
        border: none;
        word-break: break-word;
    }

    .cellLabel {
        display: none;
    }

    @media only screen and (min-width: 761px) {
        $horizontalPadding: 10px;
        $edgeHorizontalPadding: $horizontalPadding * 2;

        & {
            // NOTE: Wysokość 10 rekordów oraz 1 nagłówka na desktopie
            max-height: (47px * 10) + 58px;
        }

        thead {
            position: sticky;
            top: 0;
            transition: box-shadow $quickTransition;

            tr {
                background-color: $dark;
                color: $light;
            }
        }

        thead,
        tbody {
            display: flex;
            flex-direction: column;
        }

        tr {
            display: flex;
        }

        th {
            padding: 16px $horizontalPadding;
            font-size: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
        }

        td {
            padding: 12px $horizontalPadding;
        }

        td,
        th {
            flex: 1;
            max-width: var(--max-desktop-cell-width);
        }

        th,
        td {
            &:last-of-type {
                padding-right: $edgeHorizontalPadding;
            }

            &:first-of-type {
                padding-left: $edgeHorizontalPadding;
            }
        }
    }

    // NOTE: specjalne traktowanie laptopowej szerokości
    @media only screen and (min-width: 761px) and (max-width: 1440px) {
        thead th {
            font-size: 18px;
        }

        tbody td {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 760px) {
        & {
            max-height: 75vh;
            font-size: 16px;
        }

        thead,
        tbody,
        th,
        tr {
            display: block;
        }

        tr {
            padding: 12px 18px;
        }

        td {
            display: flex;
            padding: 2px 0;
        }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        .cellLabel {
            display: initial;
            font-weight: bold;
            padding-right: 0.75em;
            text-transform: capitalize;
        }

        .cellContent {
            flex: 1;
        }
    }
}

.headerCell {
    text-align: left;
}

.numeric {
    @media screen and (min-width: 760px) {
        text-align: right;
    }
}

.stickyHeader {
    box-shadow: 0 1px 24px -4px $opaqueDark;
}
