@import 'styles/variables';

.wrapper {
    display: flex;
    align-items: center;
    color: $light;
    font-weight: 500;
    background-color: $dark;
    padding: 0.5em 0.75em;
    transition: all $quickTransition;

    &:hover {
        background-color: mix($light, $dark, 15);
        cursor: pointer;
    }
}

.icon {
    height: 1em;
    margin-left: 0.5em;
}
