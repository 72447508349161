@import 'styles/variables';
@import 'styles/mixins';

.itemWrapper {
    flex: 1;
}

.content {
    flex: 1;
    word-break: break-word;
}
